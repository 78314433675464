import axios from 'axios'     //引入
import { Message } from 'element-ui';


//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
let baseURL = 'api.php/platform.index/index'
if (process.env.NODE_ENV === 'production') {
  let LocalURL = window.location.host
  let protocol = window.location.protocol
  // 根据前端地址动态指定baseURL
  baseURL = protocol+'//'+LocalURL+'/api.php/platform.index/index';
}

let config = {
  baseURL: baseURL,
  timeout: 30000        //设置最大请求时间
}
const _axios = axios.create(config)
const _fyaxios = axios.create(config)


/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  config => {
      //如果有需要在这里开启请求时的loading动画效果
      // config.headers.Authorization = '123';  //添加token,需要结合自己的实际情况添加，
    return config;
  },
  err => Promise.reject(err)
);
 
/* 请求之后的操作 */
_axios.interceptors.response.use(
  res => {
    //在这里关闭请求时的loading动画效果
    //这里用于处理返回的结果，比如如果是返回401无权限，可能会是跳回到登录页的操作，结合自己的业务逻辑写
    if (res.data.code === 401 ) {
        Message.warning('无权限操作')
    }
	if (res.data.code === 400 ) {
	    Message.warning("请求网络失败")
	}
  if (res.data.code === 403 ) {
      Message.warning("登录过期")
      localStorage.removeItem('user')
	}
	if (res.data.code === 404 ) {
        Message.warning("请求网络失败")
	}
    return res;
  },
  err => {
    if (err) {
      //在这里关闭请求时的loading动画效果
      Message.warning("请求网络失败")
    }
    return Promise.reject(err);
  }
);

_fyaxios.interceptors.request.use(
  config => {
    return config;
  },
  err => Promise.reject(err)
);

_fyaxios.interceptors.response.use(
  res => {
    if (res.data.ret_code != '0000' ) {
      Message.warning('上传失败')
    }
    return res;
  },
  err => {
    if (err) {
      Message.warning("上传失败")
    }
    return Promise.reject(err);
  }
);

//封装post,get方法
const http = {
  get(url='',params={}){
    let token = JSON.parse(localStorage.getItem('user'))
    if(token == null){
      params.token = ''
      localStorage.removeItem('user')
    }else{
      params.token = token.token
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers:{'Content-Type': 'application/json;charset=UTF-8'},
        method: 'GET'
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  post(url='',params={}){
    let token = JSON.parse(localStorage.getItem('user'))
    if(token == null){
      localStorage.removeItem('user')
      params.token = ''
    }else{
      params.token = token.token
    }
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data:params,
        headers:{'Content-Type': 'application/json;charset=UTF-8;multipart/form-data'},
        method: 'POST',
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 富友统一上传接口
  upload(url='',params={}){
    return new Promise((resolve, reject) => {
      _fyaxios({
        url: 'http://www-1.fuiou.com:28090/wmp/upload.fuiou?action=uploadImg',
        data: params,
        headers:{'Content-Type': 'multipart/form-data'},
        method: 'POST',
      }).then(res => {
        resolve(res.data)
        return res
      }).catch(error => {
        reject(error)
      })
    })
  }
}


export default http
