//引入刚才的http.js文件
import https from './request.js';
	
	//设置个对象，就不用一个个暴露了，直接暴露对象
	let api = {};

	// 登录
	api.login = function(params) {
		return https.post('/?action=login&method=logins', params)
	}
	// 修改密码
	api.editPass = function(params) {
		return https.post('/?action=user&method=checkPass', params)
	}


	// 数据统计
	api.dataStat = function(params) {
		return https.post('/?action=show&method=index', params)
	}
	// 提交提现账号
	api.referAccount = function(params) {
		return https.post('/?action=show&method=set_account', params)
	}
	// 获取提现账号
	api.getAccount = function(params) {
		return https.post('/?action=show&method=get_account', params)
	}
	// 余额提现
	api.balanceCashOut = function(params) {
		return https.post('/?action=show&method=tixian', params)
	}
	// 提现列表
	api.cashOutList = function(params) {
		return https.post('/?action=show&method=tixian_list', params)
	}
	// 余额明细(余额提现菜单的)
	api.balanceDetail = function(params) {
		return https.post('/?action=show&method=get_balance', params)
	}
	

	
	// 代理列表（合伙人列表、推广员列表  is_level  1:合伙人，2：推广员）
	api.getAgentList = function(params) {
		return https.post('/?action=agent&method=get_list', params)
	}
	// 合伙人提现列表
	api.agentCashOutList = function(params) {
		return https.post('/?action=agent&method=tixian', params)
	}
	// 添加合伙人
	api.addAgent = function(params) {
		return https.post('/?action=agent&method=add', params)
	}
	// 合伙人详情
	api.detailAgent = function(params) {
		return https.post('/?action=agent&method=get_info', params)
	}
	// 修改合伙人
	api.editAgent = function(params) {
		return https.post('/?action=agent&method=edit', params)
	}
	// 调整金额
	api.setBalance = function(params) {
		return https.post('/?action=agent&method=setBalance', params)
	}
	// 调整金额
	api.setBalance = function(params) {
		return https.post('/?action=agent&method=setBalance', params)
	}
	// 余额明细(合伙人管理菜单的)
	api.balanceList = function(params) {
		return https.post('/?action=agent&method=balanceList', params)
	}


	// 商家列表
	api.getStoreList = function(params) {
		return https.post('/?action=store&method=index', params)
	}
	// 添加商家
	api.addStore = function(params) {
		return https.post('/?action=store&method=add', params)
	}
	// 商家详情
	api.getStoreInfo = function(params) {
		return https.post('/?action=store&method=get_info', params)
	}
	// 修改商家信息
	api.editStore = function(params) {
		return https.post('/?action=store&method=edit', params)
	}
	// 设置代理
	api.setAgent = function(params) {
		return https.post('/?action=store&method=set_agent', params)
	}
	// 取消绑定代理
	api.cancelAgent = function(params) {
		return https.post('/?action=store&method=cancel_agent', params)
	}
	// 提现列表
	api.getWithdrawList = function(params) {
		return https.post('/?action=store&method=withdraw', params)
	}
	// 获取副管家明细
	api.fgjList = function(params) {
		return https.post('/?action=store&method=fgj', params)
	}
	// 审核提现
	api.shenheCashOut = function(params) {
		return https.post('/?action=agent&method=shenhe', params)
	}


	// 已支付订单
	api.getPaidOrder = function(params) {
		return https.post('/?action=order&method=index', params)
	}
	// 已核销订单
	api.getVerifiedOrder = function(params) {
		return https.post('/?action=order&method=verify', params)
	}
	// 已取消订单
	api.getCanceledOrder = function(params) {
		return https.post('/?action=order&method=verify_cancel', params)
	}
	// 已退款记录
	api.getRefundedOrder = function(params) {
		return https.post('/?action=order&method=refund_success', params)
	}
	// 订单详情
	api.getOrderDetails = function(params) {
		return https.post('/?action=order&method=details', params)
	}



	

	// 上传图片
	api.upImg = function(params){
		return https.post('/?action=common&method=upload_files&token='+JSON.parse(localStorage.getItem('user')).token, params)
	}
	// 富友上传图片
	api.upImg2 = function(params){
		return https.upload('', params)
	}
	// 富友图片上传签名参数
	api.getsignimg = function(params){
		return https.post('/?action=fuguanjai&method=get_signimg', params)
	}
	// 富友进件图片信息上传接口
	api.fuguanjai = function(params){
		return https.post('/?action=fuguanjai&method=mchntImgUpload', params)
	}
	// 富友信息登记接口
	api.mchntAdd = function(params){
		return https.post('/?action=fuguanjai&method=mchntAdd', params)
	}
	// 富友获取门店详情
	api.getfuyouStoreInfo = function(params){
		return https.get('/?action=fuguanjai&method=get_info', params)
	}
	// 富友获取配置参数
	api.getfuyouConfig = function(params){
		return https.get('/?action=fuguanjai&method=get_config', params)
	}
	// 富友获取城市地区
	api.getfuyouArea = function(params){
		return https.post('/?action=fuguanjai&method=get_area', params)
	}
	// 富友获取银行联行号
	api.getbanklhh = function(params){
		return https.post('/?action=fuguanjai&method=get_banklhh', params)
	}
	// 富友提交审核接口
	api.mchntAudit = function(params){
		return https.post('/?action=fuguanjai&method=mchntAudit', params)
	}
	// 富友开通状态查询接口
	api.mchntStatusQuery = function(params){
		return https.post('/?action=fuguanjai&method=mchntStatusQuery', params)
	}
	// 富友商户类别码
	api.getmcc = function(params){
		return https.get('/?action=fuguanjai&method=get_mcc', params)
	}
	// 获取设置
	api.getSetting = function(params){
		return https.post('/?action=common&method=get_tixian', params)
	}
	// 提现设置
	api.cashOutSetting = function(params){
		return https.post('/?action=common&method=tixian', params)
	}
	// 获取ai矩阵、AI实景直播
	api.getQrCode = function(params){
		return https.post('/?action=common&method=get_ai', params)
	}
	// 获取公告列表
	api.getNotice = function(params){
		return https.post('/?action=common&method=get_notice', params)
	}
	// 获取公告详情
	api.getNoticeInfo = function(params){
		return https.post('/?action=common&method=get_notice_info', params)
	}
	// 获取佣金统计数据
	api.getCount = function(params){
		return https.post('/?action=show&method=get_count', params)
	}

	
	// 获取个人信息（提现余额、提现说明）
	api.getInfo = function(params){
		return https.post('/?action=show&method=get_info', params)
	}
	// 获取入驻平台
	api.getStorePlatform = function(params){
		return https.get('/?action=store&method=get_store_platform', params)
	}

	
	//暴露出这个对象
	export default api;